import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import { Link } from "gatsby";
import { render } from 'react-dom';
import ReactRoundedImage from "react-rounded-image";
import Jojo from "../1Pics/jojo.jpg";
import Marki from "../1Pics/marki.jpg";
import { SocialIcon } from 'react-social-icons';
import Divider from '@material-ui/core/Divider';
import {Helmet} from "react-helmet";
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';
import PLZ from '../components/PLZ'

export default () => (
    <Layout>
  <Helmet>
      <meta charSet="utf-8" />
      <title>Gefährliche Abfälle</title>
      
  </Helmet>

      <section id="ablauf" className="pt-20 md:pt-20">
    <div className="container mx-auto px-8 lg:flex">
      <div className="text-center lg:text-left">
        <h3 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
         Gefährliche Abfälle - Sperrmüll, der von der Abholung ausgeschlossen ist.
        </h3><br/>
        <p className="text-l">           
        <p align="justify">Annahme dieser Gefahrengüter nicht möglich, da teilweise noch explosive, 
        giftige oder anderweitig gesundheitsgefährende Stoffe in diesen Güter vorhanden sein könnten. </p> <br/>
       
                     
        <span>&#8226;</span> Druckgaspackungen<br/>
        <span>&#8226;</span> Akkus/Akkumulatoren<br/>
        <span>&#8226;</span> Alleskleber<br/>
        <span>&#8226;</span> Altholz aus Außenbereich, mit Holzschutzmittel behandelt<br/>
        <span>&#8226;</span> Baumschnitt <br/>
        <span>&#8226;</span> Wurzelstöcke<br/>
        <span>&#8226;</span> Altkleider<br/>
        <span>&#8226;</span> Altmedikamente <br/>
        <span>&#8226;</span> Autofelgen<br/>
        <span>&#8226;</span> Farben<br/>
        <span>&#8226;</span> Autobaterien<br/>
        <span>&#8226;</span> Autoreifen<br/>
        <span>&#8226;</span> Autoteile<br/>
        <span>&#8226;</span> Dispersionsfarben<br/>
        <span>&#8226;</span> Gartenzäune/Jägerzäune<br/>
        <span>&#8226;</span> Kompressor, kraftstoffbetrieben<br/>
        <span>&#8226;</span> Kopiergeräte<br/>
        <span>&#8226;</span> Abfälle aus Gesundheitswesen<br/> 
        <span>&#8226;</span> Nachtspeicheröfen <br/>
        <span>&#8226;</span> Leuchtstoffröhren <br/>
        <span>&#8226;</span> Rasenmäher, kraftstoffbetrieben<br/> 
        <span>&#8226;</span> Schallschutzplatten mit PCB<br/>
        <span>&#8226;</span> Solarzellen usw.<br/>
        <span>&#8226;</span> Solarium<br/>
        <span>&#8226;</span> Teppichkleber<br/>
        
        
        </p>
      </div>
    </div>
        </section>
  </Layout>
  );